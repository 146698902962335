import {
  API_BASE_URL, APP_BASE_URL, FRONT_BASE_URL, MAP_SUGGESTIONS_URL, MAP_URL, MAP_URL_PLACE_NAME, GOOGLE_TAG_MANAGER_KEY
} from "../../../config/Request";


/**
 * This object holds the App related global constants.
 *
 * @type object
 */
export const AppConstants = {
  app_code: 'astro',
  app_name: 'Astro Guide',
  app_base_url: APP_BASE_URL,
  api_base_url: API_BASE_URL,
  front_base_url: FRONT_BASE_URL,
  map_suggesion_url: MAP_SUGGESTIONS_URL,
  map_url: MAP_URL,
  map_url_place_name: MAP_URL_PLACE_NAME,
  google_tag_manager_key: GOOGLE_TAG_MANAGER_KEY,
};
