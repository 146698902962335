import React, { Suspense, useEffect, useState } from 'react'
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom'
import { wtGetFromLocalStorage } from './helpers/commons/LocalStorage'
import { AppConstants } from "../src/helpers/commons/constants";
import Checkout from './views/pages/Checkout';
import Upsell from './views/pages/Upsell';
import Unsubscribe from './views/pages/Unsubscribe';
import UnsubscribeEmail from './views/pages/UnsubscribeEmail';
import Reviews from './views/pages/Reviews';
import About from './views/pages/About';
import LearnMore from './views/pages/LearnMore';
import Contact from './views/pages/Contact';
import Terms from './views/pages/Terms';
import Privacy from './views/pages/Privacy';
import Refund from './views/pages/Refund';
import Email from './views/pages/Email';
import { Helmet } from 'react-helmet';
import Loading from './helpers/commons/loader';
import PalmistryEmail from './views/pages/PalmistryEmail';
import PalmistryCheckout from './views/pages/PalmistryCheckout';



const images = [
  `${AppConstants.front_base_url}/images/astroguide-hand-holding-crystal-ball-mobile.png`,
  `${AppConstants.front_base_url}/images/astroguide-hand-holding-crystal-ball-desktop.png`,
  `${AppConstants.front_base_url}/images/astroguide-logo-color-desktop.png`,
  `${AppConstants.front_base_url}/images/astroguide-logo-darkgrey-desktop.png`,
  `${AppConstants.front_base_url}/images/astroguide-logo-white-desktop.png`,
  `${AppConstants.front_base_url}/images/astroguide-logo-white-mobile.png`,
  `${AppConstants.front_base_url}/images/palm-home.png`,
  `${AppConstants.front_base_url}/images/astroguide-sample.png`,
  `${AppConstants.front_base_url}/images/facebook.png`,
  `${AppConstants.front_base_url}/images/instagram.png`,
  `${AppConstants.front_base_url}/images/tiktok.png`,
  `${AppConstants.front_base_url}/images/whatsapp.png`,
  `${AppConstants.front_base_url}/images/app-1-adv-banner.png`,
  `${AppConstants.front_base_url}/images/app-5.2-profile-aquarius-flower.png`,
  `${AppConstants.front_base_url}/images/app-5.2-profile-aquarius-gem.png`,
  `${AppConstants.front_base_url}/images/app-5.2-profile-aquarius-planet.png`,
  `${AppConstants.front_base_url}/images/app-5.2-profile-aquarius-symbol.png`,
  `${AppConstants.front_base_url}/images/app-5.2-profile-aries-compatible.png`,
  `${AppConstants.front_base_url}/images/app-5.2-profile-aries-flower.png`,
  `${AppConstants.front_base_url}/images/app-5.2-profile-aries-gem.png`,
  `${AppConstants.front_base_url}/images/app-5.2-profile-aries-planet.png`,
  `${AppConstants.front_base_url}/images/app-5.2-profile-aries-symbol.png`,
  `${AppConstants.front_base_url}/images/app-5.2-profile-cancer-compatible.png`,
  `${AppConstants.front_base_url}/images/app-5.2-profile-cancer-flower.png`,
  `${AppConstants.front_base_url}/images/app-5.2-profile-cancer-gem.png`,
  `${AppConstants.front_base_url}/images/app-5.2-profile-cancer-planet.png`,
  `${AppConstants.front_base_url}/images/app-5.2-profile-cancer-symbol.png`,
  `${AppConstants.front_base_url}/images/app-5.2-profile-capricorn-compatible.png`,
  `${AppConstants.front_base_url}/images/app-5.2-profile-capricorn-flower.png`,
  `${AppConstants.front_base_url}/images/app-5.2-profile-capricorn-gem.png`,
  `${AppConstants.front_base_url}/images/app-5.2-profile-capricorn-planet.png`,
  `${AppConstants.front_base_url}/images/app-5.2-profile-capricorn-symbol.png`,
  `${AppConstants.front_base_url}/images/app-5.2-profile-gemini-compatible.png`,
  `${AppConstants.front_base_url}/images/app-5.2-profile-gemini-flower.png`,
  `${AppConstants.front_base_url}/images/app-5.2-profile-gemini-gem.png`,
  `${AppConstants.front_base_url}/images/app-5.2-profile-gemini-planet.png`,
  `${AppConstants.front_base_url}/images/app-5.2-profile-gemini-symbol.png`,
  `${AppConstants.front_base_url}/images/app-5.2-profile-leo-compatible.png`,
  `${AppConstants.front_base_url}/images/app-5.2-profile-leo-flower.png`,
  `${AppConstants.front_base_url}/images/app-5.2-profile-leo-gem.png`,
  `${AppConstants.front_base_url}/images/app-5.2-profile-leo-planet.png`,
  `${AppConstants.front_base_url}/images/app-5.2-profile-leo-symbol.png`,
  `${AppConstants.front_base_url}/images/app-5.2-profile-libra-compatible.png`,
  `${AppConstants.front_base_url}/images/app-5.2-profile-libra-flower.png`,
  `${AppConstants.front_base_url}/images/app-5.2-profile-libra-gem.png`,
  `${AppConstants.front_base_url}/images/app-5.2-profile-libra-planet.png`,
  `${AppConstants.front_base_url}/images/app-5.2-profile-libra-symbol.png`,
  `${AppConstants.front_base_url}/images/app-5.2-profile-pisces-compatible.png`,
  `${AppConstants.front_base_url}/images/app-5.2-profile-pisces-flower.png`,
  `${AppConstants.front_base_url}/images/app-5.2-profile-pisces-gem.png`,
  `${AppConstants.front_base_url}/images/app-5.2-profile-pisces-planet.png`,
  `${AppConstants.front_base_url}/images/app-5.2-profile-pisces-symbol.png`,
  `${AppConstants.front_base_url}/images/app-5.2-profile-sagittarius-compatible.png`,
  `${AppConstants.front_base_url}/images/app-5.2-profile-sagittarius-flower.png`,
  `${AppConstants.front_base_url}/images/app-5.2-profile-sagittarius-gem.png`,
  `${AppConstants.front_base_url}/images/app-5.2-profile-sagittarius-planet.png`,
  `${AppConstants.front_base_url}/images/app-5.2-profile-sagittarius-symbol.png`,
  `${AppConstants.front_base_url}/images/app-5.2-profile-scorpio-compatible.png`,
  `${AppConstants.front_base_url}/images/app-5.2-profile-scorpio-flower.png`,
  `${AppConstants.front_base_url}/images/app-5.2-profile-scorpio-gem.png`,
  `${AppConstants.front_base_url}/images/app-5.2-profile-scorpio-planet.png`,
  `${AppConstants.front_base_url}/images/app-5.2-profile-scorpio-symbol.png`,
  `${AppConstants.front_base_url}/images/app-5.2-profile-taurus-compatible.png`,
  `${AppConstants.front_base_url}/images/app-5.2-profile-taurus-flower.png`,
  `${AppConstants.front_base_url}/images/app-5.2-profile-taurus-gem.png`,
  `${AppConstants.front_base_url}/images/app-5.2-profile-taurus-planet.png`,
  `${AppConstants.front_base_url}/images/app-5.2-profile-taurus-symbol.png`,
  `${AppConstants.front_base_url}/images/app-5.2-profile-virgo-compatible.png`,
  `${AppConstants.front_base_url}/images/app-5.2-profile-virgo-flower.png`,
  `${AppConstants.front_base_url}/images/app-5.2-profile-virgo-gem.png`,
  `${AppConstants.front_base_url}/images/app-5.2-profile-virgo-planet.png`,
  `${AppConstants.front_base_url}/images/app-5.2-profile-virgo-symbol.png`,
  `${AppConstants.front_base_url}/images/back.png`,
  `${AppConstants.front_base_url}/images/back-white.png`,
  `${AppConstants.front_base_url}/images/checkout.png`,
  `${AppConstants.front_base_url}/images/app-5.2-profile-aquarius-compatible.png`,
  `${AppConstants.front_base_url}/images/astroguide-1-know-yourself-deeper.png`,
  `${AppConstants.front_base_url}/images/astroguide-2-enhance-your-love-life.png`,
  `${AppConstants.front_base_url}/images/astroguide-3-get-personalized-answers.png`,
  `${AppConstants.front_base_url}/images/astroguide-palmistry-mobile.png`,
  `${AppConstants.front_base_url}/images/astroguide-stars.png`,
  `${AppConstants.front_base_url}/images/astroguide-stars-quiz.png`,
  `${AppConstants.front_base_url}/images/astrology-chart.png`,
  `${AppConstants.front_base_url}/images/blinking-hearts.png`,
  `${AppConstants.front_base_url}/images/book-flipping.png`,
  `${AppConstants.front_base_url}/images/book-releasing-hearts.png`,
  `${AppConstants.front_base_url}/images/checkout-goal.png`,
  `${AppConstants.front_base_url}/images/door-opening.png`,
  `${AppConstants.front_base_url}/images/flag-on-mountain.png`,
  `${AppConstants.front_base_url}/images/hot-air-balloon.png`,
  `${AppConstants.front_base_url}/images/metronome.png`,
  `${AppConstants.front_base_url}/images/opening-treasure-chest.png`,
  `${AppConstants.front_base_url}/images/sparkling-stars.png`,
  `${AppConstants.front_base_url}/images/treasure-chest.png`,
  `${AppConstants.front_base_url}/images/upsell-astroguide-tarot-numerology.png`,
  `${AppConstants.front_base_url}/images/upsell-numerology.png`,
  `${AppConstants.front_base_url}/images/upsell-palmistry-tarot-numerology.png`,
  `${AppConstants.front_base_url}/images/upsell-tarot.png`,
  `${AppConstants.front_base_url}/images/waving-flower.png`,
  `${AppConstants.front_base_url}/images/zodiac-aquarius.png`,
  `${AppConstants.front_base_url}/images/zodiac-aries.png`,
  `${AppConstants.front_base_url}/images/zodiac-cancer.png`,
  `${AppConstants.front_base_url}/images/zodiac-capricorn.png`,
  `${AppConstants.front_base_url}/images/zodiac-gemini.png`,
  `${AppConstants.front_base_url}/images/zodiac-leo.png`,
  `${AppConstants.front_base_url}/images/zodiac-libra.png`,
  `${AppConstants.front_base_url}/images/zodiac-pisces.png`,
  `${AppConstants.front_base_url}/images/zodiac-sagittarius.png`,
  `${AppConstants.front_base_url}/images/zodiac-scorpio.png`,
  `${AppConstants.front_base_url}/images/zodiac-taurus.png`,
  `${AppConstants.front_base_url}/images/zodiac-virgo.png`,
  `${AppConstants.front_base_url}/images/map-with-heart-as-destination.png`,
  `${AppConstants.front_base_url}/images/privacy.png`,

];


function App() {

  useEffect(() => {
    document.title = AppConstants.app_name; // Set the title dynamically
  }, []);


  const AuthenticatedRoutes = ({ children }) => {
    const authUser = wtGetFromLocalStorage('authUser')
    return authUser ? children : <Navigate to="/login" />
  }

  const CheckLogin = () => {
    const authUser = wtGetFromLocalStorage('authUser')
    return authUser ? <Navigate to={authUser.type === 1 ? "/app-guide-intro" : "/Dashboard"} replace /> : <Login />
  }

  const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))
  // Pages
  const Home = React.lazy(() => import('./views/pages/Home/Home'))
  const Home2 = React.lazy(() => import('./views/pages/Home2/Home2'))
  const Home3 = React.lazy(() => import('./views/pages/Home3/Home3'))
  const Home4 = React.lazy(() => import('./views/pages/Home4/Home4'))
  const Home5 = React.lazy(() => import('./views/pages/Home5/Home5'))
  const Home6 = React.lazy(() => import('./views/pages/Home6/Home6'))
  const PalmistryHome = React.lazy(() => import('./views/pages/PalmistryHome'))
  const StartQuiz = React.lazy(() => import('./views/pages/StartQuiz'))
  const PalmistryQuiz = React.lazy(() => import('./views/pages/PalmistryQuiz'))
  const Login = React.lazy(() => import('./views/pages/Login/Login'))
  const Register = React.lazy(() => import('./views/pages/Register/Register'))
  const SignUp = React.lazy(() => import('./views/pages/SignUp/SignUp'))
  const ForgotPassword = React.lazy(() => import('./views/pages/ForgotPassword/ForgotPassword'))
  const ResetPassword = React.lazy(() => import('./views/pages/ResetPassword/ResetPassword'))
  const Page404 = React.lazy(() => import('./views/pages/Page404/Page404'))

  const [authUserWebsite, setAuthUserWebsite] = useState(wtGetFromLocalStorage('authUserWebsite'));

  useEffect(() => {
    const handleStorageChange = () => {
      setAuthUserWebsite(wtGetFromLocalStorage('authUserWebsite'));
    };

    window.addEventListener('storage', handleStorageChange);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, []);


  return (
    <Suspense fallback={Loading}>
      <input value={authUserWebsite?.email || ''}
        style={{ position: 'absolute', left: '-9999px' }}
      />
      <Helmet>
        {images.map((path, index) => (
          <link key={index} rel="preload" href={path} as="image" />
        ))}
      </Helmet>
      <BrowserRouter>
        <Routes>
          {/* Astro guide landing pages */}
          <Route exact path="/" name="Astro Landing Page" element={<Home />} />
          <Route exact path="/astrology" name="Astro Landing Page 2" element={<Home2 />} />
          <Route exact path="/astrology-profile" name="Astro Landing Page 3" element={<Home3 />} />
          <Route exact path="/astrological-zodiac-compatibility" name="Astro Landing Page 4" element={<Home4 />} />
          <Route exact path="/love-compatibility" name="Astro Landing Page 5" element={<Home5 />} />
          <Route exact path="/zodiacs" name="Astro Landing Page 6" element={<Home6 />} />

          {/* For Palmistry Landing page */}
          <Route exact path="/palmistry" name="Palmistry Landing Page" element={<PalmistryHome />} />

          <Route exact path="/quiz" name="quiz" element={<StartQuiz />} />
          <Route exact path="/palmistry-quiz" name="palmistry-quiz" element={<PalmistryQuiz />} />
          <Route exact path="/email" name="email" element={<Email />} />
          <Route exact path="/checkout" name="checkout" element={<Checkout />} />
          <Route exact path="/specials" name="specials" element={<Upsell />} />

          <Route exact path="/palmistry-email" name="Palmistry Email" element={<PalmistryEmail />} />
          <Route exact path="/palmistry-checkout" name="Palmistry Checkout" element={<PalmistryCheckout />} />
          <Route exact path="/palmistry-specials" name="Palmistry Specials" element={<Upsell />} />


          {/* TO DO: Decide about these pages */}
          {/* <Route exact path="/AstrologicalGuide" name="Astrological Guide" element={<Page404 />} />
          <Route exact path="/PalmistryReading" name="Palmistry Reading" element={<Page404 />} /> */}
          <Route exact path="/about" name="about" element={<About />} />
          <Route exact path="/learn-more" name="learn-more" element={<LearnMore />} />
          <Route exact path="/reviews" name="reviews" element={<Reviews />} />
          <Route exact path="/contact" name="contact" element={<Contact />} />
          <Route exact path="/terms" name="terms" element={<Terms />} />
          <Route exact path="/privacy" name="privacy" element={<Privacy />} />
          <Route exact path="/terms/refund" name="refund" element={<Refund />} />

          {/* TO DO */}


          <Route exact path="/unsubscribe-email" name="Unsubscribe to emails" element={<UnsubscribeEmail />} />
          <Route exact path="/unsubscribe-plan" name="Unsubscribe to subscription" element={<Unsubscribe />} />

          <Route exact path="/login" name="Login Page" element={<CheckLogin />} />
          <Route exact path="/Register" name="Register Page" element={<Register />} />
          <Route exact path="/Sign-Up" name="Sign Up" element={<SignUp />} />
          <Route exact path="/Forgot-Password" name="Forgot Password" element={<ForgotPassword />} />
          <Route exact path="/Reset-Password" name="Reset Password" element={<ResetPassword />} />

          <Route
            path='*'
            element={
              <AuthenticatedRoutes>
                <DefaultLayout />
              </AuthenticatedRoutes>
            }
          />

          <Route exact path="/404" name="Not Found" element={<Page404 />} />
        </Routes>
      </BrowserRouter>
    </Suspense>
  )
}

export default App
