import React, { useEffect, useState } from 'react';
import { Button, Col, Row, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { wtGetFromLocalStorage } from '../../../helpers/commons/LocalStorage';
import { useNavigate } from 'react-router-dom';
import '../StartQuiz/Quiz.css';
import { CheckOutlined } from '@ant-design/icons';
import { AppConstants } from '../../../helpers/commons/constants';
import { wtFormatRoundedValue } from '../../../helpers/commons/utils';

const { Title, Text } = Typography;

const Level110Quiz = ({ quiz, currency, specialOffer, getMyCopy, noThankyou }) => {
    const { t } = useTranslation('website');
    const navigate = useNavigate();
    const authTokenWebsite = wtGetFromLocalStorage('authTokenWebsite', '');


    useEffect(() => {
        if (authTokenWebsite === '' || quiz === null) {
            navigate('/');
        }
        else {
            if (+quiz.level !== 110) {
                navigate('/');
            }
        }
    }, [authTokenWebsite, quiz]);


    return (
        <React.Fragment>
            {/*  check if quiz is not null and token is not empty */}
            {(authTokenWebsite !== '' && quiz !== null && +quiz.level === 110 && specialOffer.length > 0) &&
                <Row justify="center">
                    <Col xs={24} sm={24} md={24} lg={24} xl={24} className="responsive-div">
                        <Row justify="center" style={{ marginBottom: 40 }}>
                            <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ textAlign: "center" }}>
                                <img
                                    height="140px"
                                    src={`${AppConstants.front_base_url}/images/upsell-palmistry-tarot-numerology.png`}
                                    alt="Upsell-Astrology-Tarot-Numerology"
                                />
                            </Col>
                        </Row>

                        <Row justify="center">
                            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                                <Text style={{ fontSize: 24, fontWeight: "500", color: "#fff" }}>
                                    {t('upsell_4-bundle_title')}
                                </Text>
                            </Col>
                        </Row>

                        <Row justify="center">
                            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                                <Text style={{ fontSize: 24, fontWeight: "500", color: "#fff" }}>
                                    {t('checkout_4-offer_title')}
                                </Text>
                            </Col>
                        </Row>

                        <Row justify="center" style={{ marginBottom: 20 }}>
                            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                                <Text style={{ fontSize: 24, fontWeight: "500", color: "#fff" }}>
                                    {
                                        ('is_palmistry' in quiz)
                                            ? (+quiz.is_palmistry === 1
                                                ? t('palm_10-upsell_3in1_title')
                                                : t('upsell_4-bundle_subtitle-1')
                                            )
                                            : t('upsell_4-bundle_subtitle-1')
                                    }
                                </Text>
                            </Col>
                        </Row>

                        <Row justify="center">
                            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                                <Text style={{ fontSize: 14, fontWeight: "500", color: "#fff" }}>
                                    <span dangerouslySetInnerHTML={{
                                        __html: t('upsell_4-bundle_body-1', {
                                            priceBundleOffer: wtFormatRoundedValue(specialOffer[0].discount, currency, false, 2),
                                        })
                                    }} />
                                </Text>
                            </Col>
                        </Row>

                        <Row justify="center">
                            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                                <Text style={{ fontSize: 14, fontWeight: "500", color: "#fff" }}>
                                    <span dangerouslySetInnerHTML={{
                                        __html: t('upsell_4-bundle_body-2', {
                                            priceBundleReg: wtFormatRoundedValue(specialOffer[0]?.price, currency, false, 2),
                                        })
                                    }} />
                                </Text>
                            </Col>
                        </Row>

                        <Row justify="center" style={{ marginBottom: 40 }}>
                            <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ textAlign: "center" }}>
                                <Button type="primary" size="large" className="quiz-level-5-button"
                                    onClick={() => getMyCopy()}
                                >
                                    {t('upsell_4-bundle_action')}
                                </Button>
                            </Col>
                        </Row>

                        <Row justify="center" style={{ marginBottom: 40, fontSize: 14, cursor: "pointer" }}
                            onClick={() => noThankyou()}
                        >
                            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                                <Text style={{ fontSize: 10, textDecoration: "underline", fontWeight: "500", color: "#fff" }}>
                                    {t('upsell_3-signup_skip')}
                                </Text>
                            </Col>
                        </Row>

                    </Col>
                </Row>
            }
        </React.Fragment >
    );
}

export default Level110Quiz;
