import React, { Suspense, useEffect, useState } from 'react';
import { Button, Col, Modal, Row, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import Loading from '../../../helpers/commons/loader';
import { wtGetFromLocalStorage, wtSaveToLocalStorage } from '../../../helpers/commons/LocalStorage';
import { useNavigate } from 'react-router-dom';
import { AppConstants } from '../../../helpers/commons/constants';
import '../StartQuiz/Quiz.css';
import Level107Quiz from './Level107Quiz';
import Level108Quiz from './Level108Quiz';
import { wtApiCall } from '../../../helpers/commons/ApiWrapper';
import { showErrorNotification } from '../../../helpers/commons/notifications';
import { Footer } from 'antd/es/layout/layout';
import { mapStripeError, wtFormatRoundedValue } from '../../../helpers/commons/utils';
import PaymentFailModal from '../../Widgets/PaymentFailModal';
import { loadStripe } from '@stripe/stripe-js';

const Payment = React.lazy(() => import('./Payment')); // Lazy-load Payment component

const { Text } = Typography;

const Checkout = () => {
    const { t } = useTranslation('website');
    const navigate = useNavigate();
    const authTokenWebsite = wtGetFromLocalStorage('authTokenWebsite', '');
    const authUserWebsite = wtGetFromLocalStorage('authUserWebsite', null);
    const quiz = wtGetFromLocalStorage('quiz', null);

    const [plans, setPlans] = useState([]);
    const [showSpecialOfferModal, setShowSpecialOfferModal] = useState(false)
    const [offerData, setOfferData] = useState(null);

    const [selectedTag, setSelectedTag] = useState(null);
    const [selectedPlan, setSelectedPlan] = useState(null);

    const [showPaymentModal, setShowPaymentModal] = useState(false);

    const [countries, setCountries] = useState([]);
    const [selectedCountry, setSelectedCountry] = useState(null);
    const [applyDiscount, setApplyDiscount] = useState(false);

    const [specialModalShown, setSpecialModalShown] = useState(false);



    const [showPaymentFailModal, setShowPaymentFailModal] = useState(false);
    const [paymentFailModalTitleKey, setPaymentFailModalTitleKey] = useState('');
    const [paymentFailModalTextKey, setPaymentFailModalTextKey] = useState('');
    const [isQuizFetched, setIsQuizFetched] = useState(false);
    const [removeTimer, setRemoveTimer] = useState(false);

    const [clientSecret, setClientSecret] = useState('');
    const [totalPrice, setTotalPrice] = useState(0);

    const [additionalPlan, setAdditionalPlan] = useState(null);
    const [isAdditionalSelected, setIsAdditionalSelected] = useState(false);

    const [stripePromise, setStripePromise] = useState(null);

    const getStripePublishKey = () => {
        try {
            setIsLoading(true)
            wtApiCall(
                'stripe/get_publish_key',
                {},
                'get',
                (response) => {
                    if (response && response.data) {
                        setStripePromise(loadStripe(response.data));
                    }
                    setIsLoading(false)
                },
                (error) => {
                    showErrorNotification(error.message)
                    setIsLoading(false)
                },
                true
            )
        } catch (error) {
            showErrorNotification('Error saving prices:', error);
            setIsLoading(false)
        }
    }

    useEffect(() => {
        getStripePublishKey();
    }, []);


    useEffect(() => {
        const fetchUserQuiz = async () => {
            if (authUserWebsite === null && quiz === null) {
                const queryString = window.location.search;
                const urlParams = new URLSearchParams(queryString);
                const userId = urlParams.get('userId');

                if (userId) {
                    setRemoveTimer(true);
                    await getQuizFromUser(userId);
                } else {
                    navigate('/');
                }
            }
            else {
                setIsQuizFetched(true);
            }
        };
        fetchUserQuiz();
    }, []);

    const getQuizFromUser = async (userId) => {
        try {
            setIsLoading(true)
            wtApiCall(
                'get-user',
                {
                    user_id: userId
                },
                'get',
                (response) => {
                    if (response && response.data) {
                        wtSaveToLocalStorage('quiz', JSON.parse(response.data.user_quiz.quiz));
                        wtSaveToLocalStorage('country', response.data.user_quiz.country)
                        wtSaveToLocalStorage('authUserWebsite', response.data.user);
                        wtSaveToLocalStorage('authTokenWebsite', response.data.token);
                    }
                    setIsLoading(false)
                    setIsQuizFetched(true);
                },
                (error) => {
                    showErrorNotification(error.message)
                    setIsLoading(false)
                },
                true
            )
        } catch (error) {
            showErrorNotification('Error processing!', error);
            setIsLoading(false)
        }
    }

    useEffect(() => {
        const fetchData = async () => {
            if (isQuizFetched && authUserWebsite !== null) {
                if (quiz?.level < 107) {
                    navigate('/');
                } else if (quiz?.level > 108) {
                    navigate('/specials');
                }

                if (quiz?.trial_amount) {
                    setSelectedTag(quiz.trial_amount);
                }
                if (quiz?.plan) {
                    setSelectedPlan(quiz.plan);
                }

                // Await the async functions
                await getPaymentPlans();
                await getExtraPaymentPlan();
                await getCountries();
            }
        };

        fetchData();
    }, [isQuizFetched]);

    const [isLoading, setIsLoading] = useState(false);

    const getPaymentPlans = async () => {
        const country = wtGetFromLocalStorage('country', null);

        try {
            setIsLoading(true)
            wtApiCall(
                'plans',
                {
                    service_type: 1,
                    country: country,
                },
                'get',
                (response) => {
                    if (response && response.data) {
                        setPlans(response.data);
                        if (quiz.level === 108) {
                            setSelectedPlan(response.data[0]);
                            const newQuiz = { ...quiz, plan: response.data[0] };
                            wtSaveToLocalStorage('quiz', newQuiz);
                        }
                    }
                    setIsLoading(false)
                },
                (error) => {
                    showErrorNotification(error.message)
                    setIsLoading(false)
                },
                true
            )
        } catch (error) {
            showErrorNotification('Error processing!', error);
            setIsLoading(false)
        }
    }

    const getCountries = async () => {
        try {
            setIsLoading(true)
            wtApiCall(
                'countries',
                {},
                'get',
                (response) => {
                    if (response && response.data) {
                        setCountries(response.data);

                        let defaultCountry = wtGetFromLocalStorage('country', null);
                        if (defaultCountry === null) {
                            defaultCountry = response.data.find(country => country.default === 1);
                        }
                        if (defaultCountry) {
                            setSelectedCountry(defaultCountry);
                        }
                    }
                    setIsLoading(false)
                },
                (error) => {
                    showErrorNotification(error.message)
                    setIsLoading(false)
                },
                true
            )
        } catch (error) {
            showErrorNotification('Error processing!', error);
            setIsLoading(false)
        }
    }


    const checkoutClick = () => {

        if (+quiz.level === 107) {
            if (selectedTag === null) {
                showErrorNotification('Please select an amount!');
                return;
            }
            const newQuiz = { ...quiz, level: 108, trial_amount: selectedTag, plan: selectedPlan };
            wtSaveToLocalStorage('quiz', newQuiz);
            navigate('/checkout')
        }
        if (+quiz.level === 108) {
            // const newQuiz = { ...quiz, level: 109 };
            // wtSaveToLocalStorage('quiz', newQuiz);
            openPaymentModal();
        }
    }

    useEffect(() => {
        if (isQuizFetched && authUserWebsite !== null) {
            const handleMouseMove = (event) => {
                const { clientY } = event;
                const screenHeight = window.innerHeight;

                // Only open special offer modal if payment modal is not open
                if (clientY < screenHeight * 0.01 && !showPaymentModal && !applyDiscount && !specialModalShown && +quiz.level !== 107) {
                    openSpecialOfferModal();
                    setSpecialModalShown(true);
                }
            };

            window.addEventListener("mousemove", handleMouseMove);

            return () => {
                window.removeEventListener("mousemove", handleMouseMove);
            };
        }
    }, [showPaymentModal, specialModalShown, quiz, isQuizFetched]);

    const openSpecialOfferModal = () => {
        if (!applyDiscount) {
            setShowSpecialOfferModal(true);
        }
    };


    const openPaymentModal = () => {

        if (!showSpecialOfferModal) {
            createPaymentIntent();
        }
    };

    const closePaymentModal = () => {
        setShowPaymentModal(false);
        setClientSecret('');
        setIsAdditionalSelected(false);
        if (!applyDiscount) {
            openSpecialOfferModal();
        }
    };


    const applyDiscountClick = () => {
        setApplyDiscount(true);
        setShowSpecialOfferModal(false);
    }


    const closeSpecialModal = () => {
        setShowSpecialOfferModal(false)
        // setSpecialModalShown(false);
        setShowPaymentModal(false);
    }


    const openPaymentFailModal = (messageKey) => {
        setShowPaymentFailModal(true);
        setPaymentFailModalTitleKey(messageKey.title);
        setPaymentFailModalTextKey(messageKey.text);
    }



    // ///////////////////////////////////////////////////////////
    const getExtraPaymentPlan = async () => {
        try {
            setIsLoading(true)
            wtApiCall(
                'plans',
                {
                    service_type: 2
                },
                'get',
                (response) => {
                    if (response && response.data) {
                        setAdditionalPlan(response.data);
                    }
                    setIsLoading(false)
                },
                (error) => {
                    showErrorNotification(error.message)
                    setIsLoading(false)
                },
                true
            )
        } catch (error) {
            showErrorNotification('Error processing!', error);
            setIsLoading(false)
        }
    }

    useEffect(() => {
        if (selectedPlan !== null) {
            const additionalPrice = isAdditionalSelected ? +additionalPlan[0].price : 0;

            if (+authUserWebsite.language.checkout_type === 1) {
                setTotalPrice(+selectedTag + (additionalPrice || 0));
            }
            if (+authUserWebsite.language.checkout_type === 0) {
                if (applyDiscount) {
                    setTotalPrice(+selectedPlan.extra_discount_checkout_2 + (additionalPrice || 0));
                }
                else {
                    setTotalPrice(+selectedPlan.discount_checkout_2 + (additionalPrice || 0));
                }
            }
        }
    }, [isAdditionalSelected, additionalPlan, selectedPlan, applyDiscount]);



    const additionPlanChange = () => {
        setIsAdditionalSelected(!isAdditionalSelected);
    }

    const createPaymentIntent = async () => {
        setIsLoading(true)
        try {
            if (selectedCountry !== null && selectedPlan !== null) {
                //Set Payment Details
                const paymentData = {
                    amount: totalPrice,
                    currency: selectedCountry?.currency,
                    statement_descripter: selectedPlan?.name
                };

                //call payment API
                await wtApiCall(
                    'payment/get-client-secret',
                    {
                        payment_data: paymentData,
                        email: authUserWebsite.email
                    },
                    'get',
                    (response) => {
                        if (response && response.data) {
                            //Send data to google tags
                            setClientSecret(response.data.client_secret);
                            setShowPaymentModal(true);
                        }
                        setIsLoading(false);
                    },
                    (error) => {
                        openPaymentFailModal(mapStripeError(error.message))
                        // showErrorNotification(error.message)
                        setIsLoading(false);
                        setShowPaymentModal(false);
                    },
                    true
                )
            }

        } catch (error) {
            const stripeErrorCode = String(error).replace(/^Error:/, '').trim();
            setShowPaymentModal(false);
            openPaymentFailModal(mapStripeError(stripeErrorCode))
            // showErrorNotification(error);
            setIsLoading(false);
        }
    };


    //if loading true then return spinner
    if (isLoading) {
        return <Loading />;
    }

    return (
        <React.Fragment>
            {/*  check if quiz is not null and token is not empty */}
            {(authTokenWebsite !== '' && quiz !== null) &&
                <div className='container' style={{ height: "100vh" }}>
                    <div className='quiz' style={{ background: "#fff" }}>
                        <div className="quiz-header" style={{
                            borderBottom: "1px solid rgb(248, 244, 244)",
                            boxShadow: "rgb(215, 215, 215)",
                            zIndex: 1000,
                            backgroundColor: "#ffff"
                        }}
                        >
                            <Row justify="space-between" align="middle">
                                <Col xs={10} sm={10} md={6} lg={6} xl={6} style={{ marginTop: 5 }}>
                                    <img
                                        height={28}
                                        src={`${AppConstants.front_base_url}/images/astroguide-logo-darkgrey-desktop.png`}
                                        alt="Logo"
                                        className="logo"
                                    />
                                </Col>
                                <Col xs={4} sm={4} md={12} lg={12} xl={12}></Col>
                                <Col xs={10} sm={10} md={6} lg={6} xl={6} >
                                    <Row justify="center">
                                        <Col xs={0} sm={0} md={4} lg={4} xl={4}></Col>
                                        <Col xs={24} sm={24} md={16} lg={16} xl={16} style={{ textAlign: "center" }}>
                                            <Button type="primary" size="large" className="cta-button"
                                                style={{ margin: 0, fontSize: 12, height: 34 }}
                                                onClick={() => checkoutClick()}
                                            >
                                                {t('button_getyourguide')}
                                            </Button>
                                        </Col>
                                        <Col xs={0} sm={0} md={4} lg={4} xl={4}></Col>
                                    </Row>
                                </Col>
                            </Row>

                            {applyDiscount &&
                                <Row align="middle" className="discount-banner">
                                    <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ textAlign: "center" }}>
                                        <Text style={{ color: "white", fontWeight: "bold" }}>
                                            {Math.round(((+authUserWebsite.language.checkout_type === 1) ? selectedPlan.discount_rate : (applyDiscount ? selectedPlan.extra_discount_rate_checkout_2 : selectedPlan.discount_rate_checkout_2)))}% {t('payment_2-discount_subtitle-4')}
                                        </Text>
                                    </Col>
                                </Row>
                            }

                        </div>

                        <div className='quiz-body-checkout'>
                            <Row justify="center">
                                <Col xs={24} sm={24} md={24} lg={24} xl={24}>

                                    {(+quiz.level === 107 && +authUserWebsite.language.checkout_type === 1) &&
                                        <Row>
                                            <Col xs={0} sm={0} md={5} lg={6} xl={7}></Col>
                                            <Col xs={24} sm={22} md={14} lg={12} xl={10} style={{ textAlign: "center" }}>
                                                <Level107Quiz
                                                    quiz={quiz}
                                                    plans={plans}
                                                    currency={selectedCountry?.currency.currency_symbol}
                                                    selectedTag={selectedTag}
                                                    setSelectedTag={setSelectedTag}
                                                    selectedPlan={selectedPlan}
                                                    setSelectedPlan={setSelectedPlan}
                                                    checkoutClick={() => checkoutClick()}
                                                />
                                            </Col>
                                            <Col xs={0} sm={0} md={5} lg={6} xl={7}></Col>
                                        </Row>
                                    }

                                    {(+quiz.level === 108) &&
                                        <Row>
                                            <Col xs={0} sm={1} md={6} lg={7} xl={8}></Col>
                                            <Col xs={24} sm={22} md={12} lg={10} xl={8} style={{ textAlign: "center", position: "relative", width: "100%" }}>
                                                <Level108Quiz
                                                    quiz={quiz}
                                                    currency={selectedCountry?.currency.currency_symbol}
                                                    plans={plans}
                                                    applyDiscount={applyDiscount}
                                                    checkoutClick={() => checkoutClick()}
                                                    removeTimer={removeTimer}
                                                />
                                            </Col>
                                            <Col xs={0} sm={1} md={6} lg={7} xl={8}></Col>
                                        </Row>
                                    }
                                </Col>
                            </Row>
                        </div>
                    </div>


                    {/* Footer */}
                    <Footer className='checkout-footer'>
                        <Text style={{ fontSize: 12 }}>
                            <span dangerouslySetInnerHTML={{
                                __html: t('home_7-copyright-part1') + " " + (new Date().getFullYear()) + " " + t('home_7-copyright-part2')
                            }}

                            />
                        </Text>
                        <br />
                        <Text style={{ fontSize: 12, textAlign: "center" }}>
                            <span dangerouslySetInnerHTML={{
                                __html: t('home_7-contact') + "&nbsp;&nbsp;&nbsp;" + t('home_7-terms') + "&nbsp;&nbsp;&nbsp;" + t('home_7-privacy')
                            }}
                            />
                        </Text>
                    </Footer>


                    {(selectedPlan !== null && +quiz.level === 108) &&
                        <Row justify="center">
                            <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ textAlign: "center", minHeight: 0 }}>


                                {/* Payment Component Modal */}
                                {(clientSecret !== '') &&
                                    < Modal
                                        className='payment-modal-parent'
                                        wrapClassName='payment-modal'
                                        width={500}
                                        closable={true}
                                        maskClosable={false}
                                        onCancel={() => closePaymentModal()}
                                        open={showPaymentModal}
                                        footer={[]}
                                    >
                                        <Suspense fallback={<Loading />}>
                                            <Payment
                                                selectedPlan={selectedPlan}
                                                selectedTag={selectedTag}
                                                selectedCountry={selectedCountry}
                                                applyDiscount={applyDiscount}
                                                authUserWebsite={authUserWebsite}
                                                openPaymentFailModal={(key) => openPaymentFailModal(key)}
                                                clientSecret={clientSecret}
                                                totalPrice={totalPrice}
                                                additionalPlan={additionalPlan}
                                                isAdditionalSelected={isAdditionalSelected}
                                                additionPlanChange={additionPlanChange}
                                                stripePromise={stripePromise}
                                            />
                                        </Suspense>
                                    </Modal>
                                }

                                {/* Discount Modal */}
                                {showSpecialOfferModal &&
                                    <Modal
                                        className='special-offer-modal-parent'
                                        wrapClassName='special-offer-modal'
                                        width={400}
                                        closable={false}
                                        onCancel={() => closeSpecialModal()}
                                        open={showSpecialOfferModal}
                                        footer={[
                                            <Row key="footer-row" justify="center" style={{ marginBottom: 10 }}>
                                                <Col key="footer-col-2" xs={24} sm={24} md={24} lg={24} xl={24} style={{ textAlign: "center" }}>
                                                    <Button type="primary" size="large" className="cta-button"
                                                        style={{ margin: 0, fontSize: 12, height: 34 }}
                                                        onClick={() => applyDiscountClick()}
                                                    >
                                                        {t('button_getyourdiscount')}
                                                    </Button>
                                                </Col>
                                            </Row>
                                        ]}
                                    >
                                        <Row justify="center" style={{ marginBottom: 10, marginTop: -10 }}>
                                            <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ textAlign: "center" }}>
                                                <img
                                                    height={28}
                                                    src={`${AppConstants.front_base_url}/images/astroguide-logo-darkgrey-desktop.png`}
                                                    alt="Logo"
                                                    className="logo"
                                                />
                                            </Col>
                                        </Row>
                                        <Row justify="center" style={{ marginBottom: 10, marginTop: 0 }}>
                                            <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ textAlign: "center" }}>
                                                <Text style={{ fontSize: 22, fontWeight: "bold" }}>
                                                    {`${t('payment_2-discount_title')}`}
                                                </Text>
                                            </Col>
                                        </Row>

                                        <Row justify="center" style={{ marginBottom: 10, marginTop: 0 }}>
                                            <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ textAlign: "center" }}>
                                                {+authUserWebsite.language.checkout_type === 1 &&
                                                    <Text style={{ fontSize: 26, fontWeight: "bold" }} className='checkout-title-2'>
                                                        <span dangerouslySetInnerHTML={{ __html: t('payment_2-discount_subtitle-1', { subscriptionPriceDiscountRate: wtFormatRoundedValue(selectedPlan.discount_rate, '', false, 0), }) }} />
                                                    </Text>
                                                }
                                                {+authUserWebsite.language.checkout_type === 0 &&
                                                    <Text style={{ fontSize: 26, fontWeight: "bold" }} className='checkout-title-2'>
                                                        <span dangerouslySetInnerHTML={{ __html: t('payment-flow-2_discount_subtitle-1') }} />
                                                    </Text>
                                                }
                                            </Col>
                                        </Row>


                                        <Row justify="center" style={{ marginBottom: 5 }}>
                                            <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ textAlign: "center" }}>
                                                {+authUserWebsite.language.checkout_type === 1 &&
                                                    <Text style={{ fontSize: 12, fontWeight: "bold", textAlign: "center" }}>
                                                        <span dangerouslySetInnerHTML={{ __html: t('payment_2-discount_subtitle-2', { trialDuration: selectedPlan.trial_duration, subscriptionPriceTrial: wtFormatRoundedValue(+selectedTag, selectedCountry?.currency.currency_symbol, false, 2) }) }} />
                                                    </Text>
                                                }
                                            </Col>
                                        </Row>

                                        <Row justify="center" style={{ marginBottom: 5 }}>
                                            <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ textAlign: "center" }}>
                                                {+authUserWebsite.language.checkout_type === 1 &&
                                                    <Text style={{ fontSize: 12, fontWeight: "bold", textAlign: "center" }}>
                                                        <span dangerouslySetInnerHTML={{
                                                            __html: t('payment_2-discount_subtitle-3', {
                                                                subscriptionPriceDiscount: wtFormatRoundedValue(selectedPlan.discount, selectedCountry?.currency.currency_symbol, false, 2),
                                                                subscriptionPriceRegular: wtFormatRoundedValue(selectedPlan.price, selectedCountry?.currency.currency_symbol, false, 2)
                                                            })
                                                        }} />
                                                    </Text>
                                                }
                                                {+authUserWebsite.language.checkout_type === 0 &&
                                                    <Text style={{ fontSize: 12, fontWeight: "bold", textAlign: "center" }}>

                                                        <span dangerouslySetInnerHTML={{
                                                            __html: t('payment-flow-2_discount_subtitle-2', {
                                                                subscriptionPriceExtraDiscount: wtFormatRoundedValue(selectedPlan.extra_discount_checkout_2, selectedCountry?.currency.currency_symbol, false, 2),
                                                                subscriptionPriceDiscount: wtFormatRoundedValue(selectedPlan.discount_checkout_2, selectedCountry?.currency.currency_symbol, false, 2)
                                                            })
                                                        }} />
                                                    </Text>
                                                }
                                            </Col>
                                        </Row>
                                    </Modal>
                                }



                                {/* Payment Fail Modal */}
                                {showPaymentFailModal &&
                                    <PaymentFailModal
                                        setShowPaymentFailModal={setShowPaymentFailModal}
                                        showPaymentFailModal={showPaymentFailModal}
                                        paymentFailModalTitleKey={paymentFailModalTitleKey}
                                        paymentFailModalTextKey={paymentFailModalTextKey}
                                    />
                                }
                            </Col>
                        </Row>
                    }
                </div>
            }
        </React.Fragment >
    );
}

export default Checkout;
