import React, { useEffect, useState } from 'react';
import { Col, Row, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import Loading from '../../../helpers/commons/loader';
import { wtGetFromLocalStorage } from '../../../helpers/commons/LocalStorage';
import { useNavigate } from 'react-router-dom';
import { AppConstants } from '../../../helpers/commons/constants';
import '../StartQuiz/Quiz.css';
import { Footer } from 'antd/es/layout/layout';
import Level104Quiz from './Level104Quiz';
import Level105Quiz from './Level105Quiz';
import Level106Quiz from './Level106Quiz';

const { Text } = Typography;

const PalmistryEmail = () => {
    const { t } = useTranslation('website');
    const navigate = useNavigate();
    const authTokenWebsite = wtGetFromLocalStorage('authTokenWebsite', '');
    const authUserWebsite = wtGetFromLocalStorage('authUserWebsite', null);
    const quiz = wtGetFromLocalStorage('quiz', null);

    useEffect(() => {
        if (authUserWebsite !== null) {
            if (quiz?.level < 104) {
                navigate('/');
            }
            else if (quiz?.level > 106) {
                navigate('/palmistry-checkout');
            }
        }
        else {
            navigate('/');
        }
    }, [authUserWebsite, quiz]);

    const [isLoading, setIsLoading] = useState(false);


    //if loading true then return spinner
    if (isLoading) {
        return <Loading />;
    }

    return (
        <React.Fragment>
            {/*  check if quiz is not null and token is not empty */}
            {(authTokenWebsite !== '' && quiz !== null) &&
                <div className='container' style={{ height: "100vh" }}>
                    <div className='quiz'
                        style={{
                            background:
                                ((quiz.level === 106)
                                ) ?
                                    "linear-gradient(360deg, #ff5e78ff 15%, #ab32b4ff 40%, #811cd2ff 60%, #6c11e1ff 80%, #5606efff 100%)"
                                    :
                                    "#fff"
                        }}
                    >
                        <div className="quiz-header"
                            style={{
                                borderBottom: ((quiz.level === 106)) ? 0 : "1px solid rgb(248, 244, 244)",
                                boxShadow: "rgb(215, 215, 215)",
                                zIndex: 1000,
                                backgroundColor: ((quiz.level === 106)) ? "transparent" : "#ffff",
                                position: ((quiz.level === 106)) ? "unset" : "sticky"
                            }}
                        >
                            <Row justify="space-between" align="middle" className="header-row">
                                <Col xs={4} md={4}>

                                </Col>
                                <Col xs={16} md={16} style={{ textAlign: "center" }}>
                                    <img
                                        height={32}
                                        src={(quiz.level !== 106)
                                            ? `${AppConstants.front_base_url}/images/astroguide-logo-darkgrey-desktop.png`
                                            : `${AppConstants.front_base_url}/images/astroguide-logo-white-mobile.png`
                                        }
                                        alt="Logo"
                                        className="logo"
                                    />
                                </Col>
                                <Col xs={4} md={4} className="placeholder-column" />
                            </Row>
                        </div>

                        <div className='quiz-body'>
                            <Row justify="center">
                                <Col xs={24} sm={24} md={24} lg={24} xl={24}>

                                    {(+quiz.level === 104) &&
                                        <Row>
                                            <Col xs={0} sm={0} md={5} lg={6} xl={7}>
                                                <Row justify="center">
                                                    <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ marginLeft: 30 }}>
                                                        <img
                                                            src={`${AppConstants.front_base_url}/images/astroguide-stars-quiz.png`}
                                                            alt="Astroguide Stars Quiz"
                                                            style={{ width: 250, height: 600, marginTop: 50 }}
                                                        />
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col xs={24} sm={22} md={14} lg={12} xl={10} style={{ textAlign: "center" }}>
                                                <Level104Quiz
                                                    quiz={quiz}
                                                />
                                            </Col>
                                            <Col xs={0} sm={0} md={5} lg={6} xl={7}>
                                                <Row justify="center">
                                                    <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ textAlign: "right", marginRight: 30 }}>
                                                        <img
                                                            src={`${AppConstants.front_base_url}/images/astroguide-stars-quiz.png`}
                                                            alt="Astroguide Stars Quiz"
                                                            style={{ width: 250, height: 600, marginTop: 50 }}
                                                        />
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    }

                                    {(+quiz.level === 105) &&
                                        <Row>
                                            <Col xs={0} sm={0} md={0} lg={7} xl={8}>
                                                <Row justify="center">
                                                    <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ marginLeft: 30 }}>
                                                        <img
                                                            src={`${AppConstants.front_base_url}/images/astroguide-stars-quiz.png`}
                                                            alt="Astroguide Stars Quiz"
                                                            style={{ width: "auto", height: "auto", maxHeight: "80vh", marginTop: 20 }}
                                                        />
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col xs={0} sm={0} md={6} lg={0} xl={0}></Col>
                                            <Col xs={24} sm={24} md={12} lg={10} xl={8} style={{ textAlign: "center" }}>
                                                <Row>
                                                    <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ textAlign: "center" }}>
                                                        <Level105Quiz
                                                            quiz={quiz}
                                                        />
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col xs={0} sm={0} md={6} lg={0} xl={0}></Col>

                                            <Col xs={0} sm={0} md={0} lg={7} xl={8}>
                                                <Row justify="center">
                                                    <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ textAlign: "right", marginRight: 30 }}>
                                                        <img
                                                            src={`${AppConstants.front_base_url}/images/astroguide-stars-quiz.png`}
                                                            alt="Astroguide Stars Quiz"
                                                            style={{ width: "auto", height: "auto", maxHeight: "80vh", marginTop: 20 }}
                                                        />
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>

                                    }
                                    {(+quiz.level === 106) &&
                                        <Level106Quiz
                                            quiz={quiz}
                                        />
                                    }
                                </Col>
                            </Row>
                        </div>
                    </div>
                </div>
            }
        </React.Fragment >
    );
}

export default PalmistryEmail;
