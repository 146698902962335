import {
    HomeOutlined,
    StarOutlined,
    SkinOutlined ,
    InfoCircleOutlined,
    BookOutlined,
    MessageOutlined,
    LoginOutlined,
    UserOutlined,
    LockOutlined
} from '@ant-design/icons';
import LanguageSwitcher from '../layout/LanguageSwitcher';
import { wtGetFromLocalStorage } from '../helpers/commons/LocalStorage';

const authUser = wtGetFromLocalStorage('authUser', null);

export const navWebsite = [
    {
        key: '1',
        icon: <HomeOutlined />,
        label: 'Home',
        to: '/',
    },
    {
        key: '2',
        icon: <StarOutlined />,
        label: 'Palmistry',
        to: '/palmistry',
    },
    // {
    //     key: '3',
    //     icon: <SmileOutlined />,
    //     label: 'Palmistry Reading',
    //     to: '/PalmistryReading',
    // },
    {
        key: '4',
        icon: <InfoCircleOutlined />,
        label: 'About',
        to: '/about',
    },
    {
        key: '5',
        icon: <BookOutlined />,
        label: 'Learn More',
        to: '/learn-more',
    },
    {
        key: '6',
        icon: <MessageOutlined />,
        label: 'Reviews',
        to: '/reviews',
    },
    {
        key: '7',
        icon: <LockOutlined />,
        label: 'Privacy',
        to: '/privacy',
    },
    // {
    //     key: '7',
    //     icon: <MinusCircleOutlined />,
    //     label: 'Unsubscribe',
    //     to: '/unsubscribe-plan',
    // },
    {
        key: '8',
        icon: authUser ? <UserOutlined /> : <LoginOutlined />,
        label: authUser ? authUser.name : 'Account Login',
        to: '/login',
    },
    {
        key: '90',
        icon: "",
        label: <LanguageSwitcher />,
    }
];