import React, { useEffect, useState } from 'react';
import { Button, Col, Row, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import Loading from '../../../helpers/commons/loader';
import { wtGetFromLocalStorage } from '../../../helpers/commons/LocalStorage';
import { useNavigate } from 'react-router-dom';
import { AppConstants } from '../../../helpers/commons/constants';
import { wtFormatRoundedValue, wtLogOut } from '../../../helpers/commons/utils';
import Carousel from '../../Widgets/Carousel';

const { Text } = Typography;

const Level108Quiz = ({ quiz, currency, applyDiscount, checkoutClick, removeTimer }) => {
    const { t } = useTranslation('website');
    const navigate = useNavigate();
    const authTokenWebsite = wtGetFromLocalStorage('authTokenWebsite', '');
    const authUserWebsite = wtGetFromLocalStorage('authUserWebsite', null);
    const [selectedTag, setSelectedTag] = useState(null);
    const [selectedPlan, setSelectedPlan] = useState(null);

    // Set initial time to 15 minutes (900 seconds)
    const initialTime = 15 * 60; // 15 minutes in seconds
    const [timeRemaining, setTimeRemaining] = useState(initialTime);
    const [isDesktop, setIsDesktop] = React.useState(window.innerWidth >= 1024);

    useEffect(() => {
        const handleResize = () => {
            setIsDesktop(window.innerWidth >= 1024);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);



    useEffect(() => {
        if (authTokenWebsite === '' || quiz === null) {
            navigate('/');
        }
        else {
            if (+quiz.level !== 108) {
                navigate('/');
            }
        }
        if (quiz?.trial_amount) {
            setSelectedTag(quiz.trial_amount);
        }
        if (quiz?.plan) {
            setSelectedPlan(quiz.plan);
        }
    }, [authTokenWebsite, quiz]);

    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        // Set up the interval to update the time every second
        const intervalId = setInterval(() => {
            setTimeRemaining(prevTime => {
                if (prevTime > 0) {
                    return prevTime - 1;
                } else {
                    clearInterval(intervalId); // Stop the interval when time reaches 0
                    return 0;
                    wtLogOut();
                }
            });
        }, 1000); // Update every second (1000ms)

        // Clean up interval on component unmount
        return () => clearInterval(intervalId);
    }, []);

    // Function to format time in minutes and seconds
    const formatTime = (seconds) => {
        const minutes = Math.floor(seconds / 60);
        const remainingSeconds = seconds % 60;
        return `${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;
    };


    //if loading true then return spinner
    if (isLoading) {
        return <Loading />;
    }

    return (
        <React.Fragment>
            {(authTokenWebsite !== '' && quiz !== null && selectedPlan !== null) &&
                <Row justify="center">
                    <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ paddingBottom: 0 }}>

                        {/* For Both single and couple both */}
                        <Col xs={24} sm={24} md={24} lg={24} xl={24}>

                            <Row justify="center" style={{ marginTop: 20, marginBottom: 40 }}>

                                <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ backgroundColor: "#F5F5F7", borderRadius: 20, padding: 20 }}>

                                    <Row justify="center" style={{ marginBottom: 10, fontSize: 14 }}>
                                        <Col xs={2} sm={2} md={2} lg={2} xl={2}></Col>
                                        <Col xs={20} sm={20} md={20} lg={20} xl={20} style={{ textAlign: "center", fontWeight: "bold" }}>
                                            <Text>
                                                {t('palm_7-checkout_1-timer_title')}
                                            </Text>
                                        </Col>
                                        <Col xs={2} sm={2} md={2} lg={2} xl={2}></Col>
                                    </Row>
                                    {!removeTimer &&
                                        <>
                                            <Row justify="center" style={{ marginBottom: 10 }}>
                                                <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ textAlign: "center" }}>
                                                    <Text style={{ fontSize: 12 }}>
                                                        {t('checkout_2-timer_body')}
                                                    </Text>
                                                </Col>
                                            </Row>

                                            <Row justify="center" style={{ marginBottom: 10, fontSize: 12 }}>
                                                <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ textAlign: "center" }}>
                                                    <Text style={{ fontSize: 12 }}>
                                                        {formatTime(timeRemaining)}
                                                    </Text>
                                                </Col>
                                            </Row>
                                        </>
                                    }
                                </Col>


                            </Row>


                            <Row justify="center">
                                <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ textAlign: "center" }}>
                                    <Text className='checkout-title-4'
                                        style={{ fontSize: isDesktop ? 34 : 28 }}>
                                        {t('palm_7-checkout_1-timer_title-1')}
                                    </Text>
                                </Col>
                            </Row>

                            <Row justify="center">
                                <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ textAlign: "center" }}>
                                    <Text className='checkout-title-4'
                                        style={{ fontSize: isDesktop ? 34 : 28 }}>
                                        {t('palm_7-checkout_1-timer_title-2')}
                                    </Text>
                                </Col>
                            </Row>


                            <Row justify="center" style={{ marginBottom: 40 }}>
                                <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ textAlign: "center" }}>
                                    <Text className='checkout-title-4'
                                        style={{ fontSize: isDesktop ? 34 : 28 }}>
                                        {t('palm_7-checkout_1-timer_title-3')}
                                    </Text>
                                </Col>
                            </Row>


                            <Row justify="center" style={{ marginBottom: 40 }}>
                                <Col
                                    xs={24} sm={24} md={24} lg={24} xl={24}
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        textAlign: "center"
                                    }}
                                >
                                    <img
                                        height={isDesktop ? 280 : 200}
                                        style={{ maxWidth: '100%' }}
                                        src={`${AppConstants.front_base_url}/images/astroguide-palmistry-mobile.png`}
                                        alt="Astroguide Sample"
                                    />
                                </Col>
                            </Row>

                            <Row justify="center" style={{ marginBottom: 40 }}>
                                <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ textAlign: "center" }}>
                                    <Text style={{ fontSize: 26, fontWeight: "500" }}>
                                        {t('palm_7-checkout_1-timer_body')}
                                    </Text>
                                </Col>
                            </Row>


                            <Row className='full-background-grey-row'>
                                <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ textAlign: "center" }}>

                                    <Row justify="center" style={{ marginTop: 40 }}>
                                        <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ textAlign: "center" }}>
                                            <Text style={{ fontSize: 18, fontWeight: "700" }}>
                                                {t('checkout_4-offer_title')}
                                            </Text>
                                        </Col>
                                    </Row>

                                    <Row justify="center" style={{ marginBottom: 40 }}>
                                        <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ textAlign: "center" }}>
                                            <Text style={{ fontSize: 24, fontWeight: "bold", display: "block" }}>
                                                <span dangerouslySetInnerHTML={{
                                                    __html: t('palm_7-checkout_2-offer_body', {
                                                        pricePersonalizedReading: wtFormatRoundedValue(
                                                            (+authUserWebsite.language.checkout_type === 1) ? +selectedTag : (applyDiscount) ? selectedPlan.extra_discount_checkout_2 : selectedPlan.discount_checkout_2, currency, false, 2
                                                        ),
                                                    })
                                                }} />
                                            </Text>
                                        </Col>
                                    </Row>


                                    <Row justify="center">
                                        <Col xs={1} sm={1} md={2} lg={2} xl={2} ></Col>

                                        <Col xs={22} sm={22} md={20} lg={20} xl={20} style={{ textAlign: "center" }}>
                                            <div className="special-offer-thumbnail-container" style={{ width: isDesktop ? 350 : 300 }}>
                                                <div className="special-offer-thumbnail-content">
                                                    <Row justify="center" style={{ marginBottom: 20 }}>
                                                        <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ textAlign: "center" }}>
                                                            <Text style={{ fontSize: 20, fontWeight: "bold" }}>
                                                                {t('checkout_5-offerbox_title')}
                                                            </Text>
                                                        </Col>
                                                    </Row>

                                                    <Row justify="center">
                                                        <Col xs={12} sm={12} md={12} lg={12} xl={12} style={{ textAlign: "left" }}>
                                                            {+authUserWebsite.language.checkout_type === 1 &&
                                                                <Text style={{ fontSize: 13, fontWeight: "500" }}>
                                                                    {t('checkout_5-offerbox_subtitle-1')}
                                                                </Text>
                                                            }
                                                            {+authUserWebsite.language.checkout_type === 0 &&
                                                                <Text style={{ fontSize: 13, fontWeight: "500" }}>
                                                                    {t('checkout_5-flow-2_special')}
                                                                </Text>
                                                            }
                                                        </Col>
                                                        <Col xs={12} sm={12} md={12} lg={12} xl={12} style={{ textAlign: "right" }}>
                                                            <Text style={{ fontSize: 13, fontWeight: "500" }}>
                                                                {currency + ((+authUserWebsite.language.checkout_type === 0) ? wtFormatRoundedValue((applyDiscount) ? selectedPlan.extra_discount_checkout_2 : selectedPlan.discount_checkout_2, '', false, 2) : wtFormatRoundedValue(+selectedTag, '', false, 2))}
                                                            </Text>
                                                        </Col>
                                                    </Row>

                                                    <hr style={{ width: "100%" }} />

                                                    <Row justify="center">
                                                        {+authUserWebsite.language.checkout_type === 0 &&
                                                            <>
                                                                <Col xs={8} sm={8} md={8} lg={8} xl={8} style={{ textAlign: "left" }}>
                                                                    <Text style={{ fontSize: 13, fontWeight: "500" }}>
                                                                        {t('checkout_5-flow-2_regular')}
                                                                    </Text>
                                                                </Col>
                                                                <Col xs={6} sm={6} md={6} lg={6} xl={6} style={{ textAlign: "right" }}>
                                                                    <Text className='upsell-colored-title'>
                                                                        <span dangerouslySetInnerHTML={{
                                                                            __html: t('checkout_5-flow-2_save', {
                                                                                totalDiscountPercentage: wtFormatRoundedValue(applyDiscount ? selectedPlan.extra_discount_rate_checkout_2 : selectedPlan.discount_rate_checkout_2, '', false, 0),
                                                                            })
                                                                        }} />
                                                                    </Text>
                                                                </Col>
                                                            </>
                                                        }

                                                        {+authUserWebsite.language.checkout_type === 1 &&
                                                            <Col xs={14} sm={14} md={14} lg={14} xl={14} style={{ textAlign: "left" }}>
                                                                <Text style={{ fontSize: 13, fontWeight: "500" }}>
                                                                    {t('checkout_5-offerbox_subtitle-2')}
                                                                </Text>
                                                            </Col>
                                                        }
                                                        <Col xs={10} sm={10} md={10} lg={10} xl={10} style={{ textAlign: "right" }}>
                                                            {+authUserWebsite.language.checkout_type === 1 &&
                                                                <Text style={{ fontSize: 13, fontWeight: "500" }}>
                                                                    {currency + wtFormatRoundedValue(applyDiscount ? selectedPlan.discount : selectedPlan.price, '', false, 2)}
                                                                </Text>
                                                            }
                                                            {+authUserWebsite.language.checkout_type === 0 &&
                                                                <Text style={{ fontSize: 13, fontWeight: "500" }}>
                                                                    <s>{currency + wtFormatRoundedValue(selectedPlan.price_checkout_2, '', false, 2)}</s>
                                                                </Text>
                                                            }
                                                        </Col>
                                                    </Row>
                                                </div>
                                            </div>
                                        </Col>

                                        <Col xs={1} sm={1} md={2} lg={2} xl={2} ></Col>
                                    </Row>

                                    <Row justify="center" style={{ marginBottom: 40 }}>
                                        <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ textAlign: "center" }}>
                                            <Button type="primary" size="large" className="cta-button"
                                                style={{ maxWidth: 300 }}
                                                onClick={() => checkoutClick()}
                                            >
                                                {t('button_getyourreading')}
                                            </Button>
                                        </Col>
                                    </Row>


                                </Col>
                            </Row>


                            <Row justify="center" style={{ marginBottom: 10 }}>
                                <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ textAlign: "center" }}>
                                    <Text style={{ fontSize: 18, fontWeight: "600" }}>
                                        {t('palm_7-checkout_4-insight_title')}
                                    </Text>
                                </Col>
                            </Row>

                            <Row justify="center" style={{ marginBottom: 40 }}>
                                <Col xs={2} sm={2} md={4} lg={4} xl={4}></Col>
                                <Col xs={22} sm={22} md={16} lg={16} xl={16} style={{ textAlign: "center" }}>
                                    <Text style={{ fontSize: 26, fontWeight: "bold" }}>
                                        {t('palm_7-checkout_4-insight_subtitle-1')}
                                    </Text>
                                </Col>
                                <Col xs={2} sm={2} md={4} lg={4} xl={4}></Col>
                            </Row>

                            <Row justify="center" style={{ marginBottom: 40 }}>
                                <Col xs={2} sm={2} md={4} lg={4} xl={4}></Col>
                                <Col xs={22} sm={22} md={16} lg={16} xl={16} style={{ textAlign: "left" }}>
                                    <Text style={{ fontSize: 14, fontWeight: "400" }}>
                                        {t('palm_7-checkout_4-insight_subtitle-2')}
                                    </Text>
                                </Col>
                                <Col xs={2} sm={2} md={4} lg={4} xl={4}></Col>
                            </Row>

                            <Row justify="center" style={{ marginBottom: 40 }}>
                                <Col
                                    xs={24} sm={24} md={24} lg={24} xl={24}
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        textAlign: "center"
                                    }}
                                >
                                    <img
                                        height={isDesktop ? 280 : 200}
                                        style={{ maxWidth: '100%' }}
                                        src={`${AppConstants.front_base_url}/images/astroguide-palmistry-mobile.png`}
                                        alt="Astroguide Sample"
                                    />
                                </Col>
                            </Row>



                            {/* Love Line*/}

                            <Row justify="center" style={{ marginBottom: 10 }}>
                                <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ textAlign: "center" }}>
                                    <Text style={{ fontSize: 22, fontWeight: "bold", color: "#ff0000" }}>
                                        {t('palm_4-scan_6-pop_identifying_part-7') + " " + t('palm_4-scan_6-pop_identifying_part-8')}
                                    </Text>
                                </Col>
                            </Row>

                            <Row justify="center" style={{ marginBottom: 40 }}>
                                <Col xs={0} sm={0} md={1} lg={1} xl={1}></Col>
                                <Col xs={24} sm={24} md={22} lg={22} xl={22} style={{ textAlign: "center" }}>
                                    <Text style={{ fontSize: 14 }}>
                                        {t('palm_7-checkout_4-insight_body-1')}
                                    </Text>
                                </Col>
                                <Col xs={0} sm={0} md={1} lg={1} xl={1}></Col>
                            </Row>


                            {/* Head Line */}
                            <Row justify="center" style={{ marginBottom: 10 }}>
                                <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ textAlign: "center" }}>
                                    <Text style={{ fontSize: 22, fontWeight: "bold", color: "#00d6f5" }}>
                                        {t('palm_4-scan_6-pop_identifying_part-11')}
                                    </Text>
                                </Col>
                            </Row>

                            <Row justify="center" style={{ marginBottom: 40 }}>
                                <Col xs={0} sm={0} md={1} lg={1} xl={1}></Col>
                                <Col xs={24} sm={24} md={22} lg={22} xl={22} style={{ textAlign: "center" }}>
                                    <Text style={{ fontSize: 14 }}>
                                        {t('palm_7-checkout_4-insight_body-2')}
                                    </Text>
                                </Col>
                                <Col xs={0} sm={0} md={1} lg={1} xl={1}></Col>
                            </Row>


                            {/* Life Line */}
                            <Row justify="center" style={{ marginBottom: 10 }}>
                                <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ textAlign: "center" }}>
                                    <Text style={{ fontSize: 22, fontWeight: "bold", color: "#71e087" }}>
                                        {t('palm_4-scan_6-pop_identifying_part-6')}
                                    </Text>
                                </Col>
                            </Row>

                            <Row justify="center" style={{ marginBottom: 40 }}>
                                <Col xs={0} sm={0} md={1} lg={1} xl={1}></Col>
                                <Col xs={24} sm={24} md={22} lg={22} xl={22} style={{ textAlign: "center" }}>
                                    <Text style={{ fontSize: 14 }}>
                                        {t('palm_7-checkout_4-insight_body-3')}
                                    </Text>
                                </Col>
                                <Col xs={0} sm={0} md={1} lg={1} xl={1}></Col>
                            </Row>

                            {/* Money Line */}
                            <Row justify="center" style={{ marginBottom: 10 }}>
                                <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ textAlign: "center" }}>
                                    <Text style={{ fontSize: 22, fontWeight: "bold", color: "#ffc305" }}>
                                        {t('palm_4-scan_6-pop_identifying_part-9') + ' ' + t('palm_4-scan_6-pop_identifying_part-10')}
                                    </Text>
                                </Col>
                            </Row>

                            <Row justify="center" style={{ marginBottom: 40 }}>
                                <Col xs={0} sm={0} md={1} lg={1} xl={1}></Col>
                                <Col xs={24} sm={24} md={22} lg={22} xl={22} style={{ textAlign: "center" }}>
                                    <Text style={{ fontSize: 14 }}>
                                        {t('palm_7-checkout_4-insight_body-4')}
                                    </Text>
                                </Col>
                                <Col xs={0} sm={0} md={1} lg={1} xl={1}></Col>
                            </Row>


                            {/* Marriage Line */}
                            <Row justify="center" style={{ marginBottom: 10 }}>
                                <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ textAlign: "center" }}>
                                    <Text style={{ fontSize: 22, fontWeight: "bold", color: "#ff6701" }}>
                                        {t('palm_4-scan_6-pop_identifying_part-12')}
                                    </Text>
                                </Col>
                            </Row>

                            <Row justify="center" style={{ marginBottom: 40 }}>
                                <Col xs={0} sm={0} md={1} lg={1} xl={1}></Col>
                                <Col xs={24} sm={24} md={22} lg={22} xl={22} style={{ textAlign: "center" }}>
                                    <Text style={{ fontSize: 14 }}>
                                        {t('palm_7-checkout_4-insight_body-5')}
                                    </Text>
                                </Col>
                                <Col xs={0} sm={0} md={1} lg={1} xl={1}></Col>
                            </Row>

                            {/* ////What you get//// */}

                            <Row className='full-background-grey-row'>
                                <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ textAlign: "center", marginTop: 40 }}>
                                    <Row justify="center">
                                        <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ textAlign: "center" }}>
                                            <Text style={{ fontSize: 15, fontWeight: "500" }}>
                                                {t('checkout_12-content_title')}
                                            </Text>
                                        </Col>
                                    </Row>

                                    <Row justify="center" style={{ marginBottom: 50 }}>
                                        <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ textAlign: "center" }}>
                                            <Text style={{ fontSize: 26, fontWeight: "bold" }}>
                                                {t('palm_7-checkout_5-content_subtitle')}
                                            </Text>
                                        </Col>
                                    </Row>

                                    <Row justify="center" style={{ marginBottom: 20 }}>
                                        <Col xs={2} sm={2} md={2} lg={2} xl={2}></Col>
                                        <Col xs={20} sm={20} md={20} lg={20} xl={20} style={{ textAlign: "center" }}>
                                            <Text style={{ color: "#5606ef", fontSize: 20, fontWeight: "500" }}>
                                                {t('checkout_12-content_body-6')}
                                            </Text>
                                        </Col>
                                        <Col xs={2} sm={2} md={2} lg={2} xl={2}></Col>
                                    </Row>

                                    <Row justify="center" style={{ marginBottom: 20 }}>
                                        <Col xs={2} sm={2} md={2} lg={2} xl={2}></Col>
                                        <Col xs={20} sm={20} md={20} lg={20} xl={20} style={{ textAlign: "center" }}>
                                            <Text style={{ color: "#8e00fd", fontSize: 20, fontWeight: "500" }}>
                                                {t('checkout_12-content_body-7')}
                                            </Text>
                                        </Col>
                                        <Col xs={2} sm={2} md={2} lg={2} xl={2}></Col>
                                    </Row>


                                    <Row justify="center" style={{ marginBottom: 40 }}>
                                        <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ textAlign: "center" }}>
                                            <Text style={{ fontSize: 15, fontWeight: "500" }}>
                                                {t('palm_7-checkout_5-content_body-1')}
                                            </Text>
                                        </Col>
                                    </Row>


                                    <Row justify="center" style={{ marginBottom: 40 }}>
                                        <Col xs={2} sm={2} md={8} lg={8} xl={8}></Col>
                                        <Col xs={22} sm={22} md={8} lg={8} xl={8} style={{ textAlign: "left" }}>
                                            <Text style={{ fontSize: 14, fontWeight: "400" }}>
                                                {t('palm_7-checkout_5-content_body-2')}
                                            </Text>
                                        </Col>
                                        <Col xs={2} sm={2} md={8} lg={8} xl={8}></Col>
                                    </Row>

                                    <Row justify="center" style={{ marginBottom: 40 }}>
                                        <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ textAlign: "center" }}>
                                            <Button type="primary" size="large" className="cta-button"
                                                style={{ maxWidth: 300 }}
                                                onClick={() => checkoutClick()}
                                            >
                                                {t('button_getyourreading')}
                                            </Button>
                                        </Col>
                                    </Row>


                                </Col>
                            </Row>

                            {/* Testimonials */}
                            <Row className='full-background-grey-row' style={{ backgroundColor: "#fff" }}>
                                <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ textAlign: "center", marginTop: 60 }}>

                                    <Row justify="center" style={{ marginBottom: 0 }}>
                                        <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ textAlign: "center" }}>
                                            <Text style={{ fontSize: 18, fontWeight: "600" }}>
                                                {t('home_4-testimonials_title')}
                                            </Text>
                                        </Col>
                                    </Row>

                                    <Row justify="center" style={{ marginBottom: 0 }}>
                                        <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ textAlign: "center" }}>
                                            <Text style={{ fontSize: 25, fontWeight: "bold" }}>
                                                {t('checkout_13-testimonials_subtitle-1')}
                                            </Text>
                                        </Col>
                                    </Row>

                                    <Row justify="center" style={{ marginBottom: 30 }}>
                                        <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ textAlign: "center" }}>
                                            <Text style={{ fontSize: 25, fontWeight: "bold" }}>
                                                {t('checkout_13-testimonials_subtitle-2')}
                                            </Text>
                                        </Col>
                                    </Row>

                                    {/* Carosoule */}
                                    <Row justify="center" style={{ marginBottom: 0 }}>
                                        <Col xs={0} sm={0} md={3} lg={4} xl={5}></Col>
                                        <Col xs={24} sm={24} md={18} lg={16} xl={14} style={{ textAlign: "center" }}>
                                            <Carousel slidesToShow={isDesktop ? 3 : 1.5} infinite={isDesktop ? true : false} />
                                        </Col>
                                        <Col xs={0} sm={0} md={3} lg={4} xl={5}></Col>
                                    </Row>


                                </Col>
                            </Row>

                            {/* //////////////Special Offer//////////////// */}

                            <Row className='full-background-grey-row' style={{ marginBottom: 0 }}>
                                <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ textAlign: "center", marginTop: 20 }}>

                                    <Row justify="center" style={{ marginTop: 20, marginBottom: 20 }}>
                                        <Col xs={1} sm={1} md={2} lg={2} xl={2} ></Col>

                                        <Col xs={22} sm={22} md={20} lg={20} xl={20} style={{ textAlign: "center" }}>
                                            <div className="special-offer-thumbnail-container" style={{ width: isDesktop ? 350 : 300 }}>
                                                <div className="special-offer-thumbnail-content">
                                                    <Row justify="center" style={{ marginBottom: 20 }}>
                                                        <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ textAlign: "center" }}>
                                                            <Text style={{ fontSize: 20, fontWeight: "bold" }}>
                                                                {t('checkout_5-offerbox_title') + " " +
                                                                    wtFormatRoundedValue(
                                                                        (+authUserWebsite.language.checkout_type === 1) ? +selectedTag : (applyDiscount) ? selectedPlan.extra_discount_checkout_2 : selectedPlan.discount_checkout_2, currency, false, 2
                                                                    )
                                                                }
                                                            </Text>
                                                        </Col>
                                                    </Row>

                                                    <Row justify="center">
                                                        <Col xs={12} sm={12} md={12} lg={12} xl={12} style={{ textAlign: "left" }}>
                                                            {+authUserWebsite.language.checkout_type === 1 &&
                                                                <Text style={{ fontSize: 13, fontWeight: "500" }}>
                                                                    {t('checkout_5-offerbox_subtitle-1')}
                                                                </Text>
                                                            }
                                                            {+authUserWebsite.language.checkout_type === 0 &&
                                                                <Text style={{ fontSize: 13, fontWeight: "500" }}>
                                                                    {t('checkout_5-flow-2_special')}
                                                                </Text>
                                                            }

                                                        </Col>
                                                        <Col xs={12} sm={12} md={12} lg={12} xl={12} style={{ textAlign: "right" }}>
                                                            <Text style={{ fontSize: 13, fontWeight: "500" }}>
                                                                {currency + ((+authUserWebsite.language.checkout_type === 0) ? wtFormatRoundedValue((applyDiscount) ? selectedPlan.extra_discount_checkout_2 : selectedPlan.discount_checkout_2, '', false, 2) : wtFormatRoundedValue(+selectedTag, '', false, 2))}
                                                            </Text>
                                                        </Col>
                                                    </Row>

                                                    <hr style={{ width: "100%" }} />

                                                    <Row justify="center">
                                                        {+authUserWebsite.language.checkout_type === 0 &&
                                                            <>
                                                                <Col xs={8} sm={8} md={8} lg={8} xl={8} style={{ textAlign: "left" }}>
                                                                    <Text style={{ fontSize: 13, fontWeight: "500" }}>
                                                                        {t('checkout_5-flow-2_regular')}
                                                                    </Text>
                                                                </Col>
                                                                <Col xs={6} sm={6} md={6} lg={6} xl={6} style={{ textAlign: "right" }}>
                                                                    <Text className='upsell-colored-title'>
                                                                        <span dangerouslySetInnerHTML={{
                                                                            __html: t('checkout_5-flow-2_save', {
                                                                                totalDiscountPercentage: wtFormatRoundedValue(applyDiscount ? selectedPlan.extra_discount_rate_checkout_2 : selectedPlan.discount_rate_checkout_2, '', false, 0),
                                                                            })
                                                                        }} />
                                                                    </Text>
                                                                </Col>
                                                            </>
                                                        }

                                                        {+authUserWebsite.language.checkout_type === 1 &&
                                                            <Col xs={14} sm={14} md={14} lg={14} xl={14} style={{ textAlign: "left" }}>
                                                                <Text style={{ fontSize: 13, fontWeight: "500" }}>
                                                                    {t('checkout_5-offerbox_subtitle-2')}
                                                                </Text>
                                                            </Col>
                                                        }
                                                        <Col xs={10} sm={10} md={10} lg={10} xl={10} style={{ textAlign: "right" }}>
                                                            {+authUserWebsite.language.checkout_type === 1 &&
                                                                <Text style={{ fontSize: 13, fontWeight: "500" }}>
                                                                    {currency + wtFormatRoundedValue(applyDiscount ? selectedPlan.discount : selectedPlan.price, '', false, 2)}
                                                                </Text>
                                                            }
                                                            {+authUserWebsite.language.checkout_type === 0 &&
                                                                <Text style={{ fontSize: 13, fontWeight: "500" }}>
                                                                    <s>{currency + wtFormatRoundedValue(selectedPlan.price_checkout_2, '', false, 2)}</s>
                                                                </Text>
                                                            }
                                                        </Col>
                                                    </Row>
                                                </div>
                                            </div>
                                        </Col>

                                        <Col xs={1} sm={1} md={2} lg={2} xl={2} ></Col>
                                    </Row>

                                    <Row justify="center" style={{ marginBottom: 40 }}>
                                        <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ textAlign: "center" }}>
                                            <Button type="primary" size="large" className="cta-button"
                                                style={{ maxWidth: 300 }}
                                                onClick={() => checkoutClick()}
                                            >
                                                {t('button_getyourreading')}
                                            </Button>
                                        </Col>
                                    </Row>


                                    {+authUserWebsite.language.checkout_type === 1 &&
                                        <Row justify="center" style={{ marginBottom: 0 }}>
                                            <Col xs={2} sm={2} md={7} lg={7} xl={7}></Col>
                                            <Col xs={20} sm={20} md={6} lg={6} xl={6} style={{ textAlign: "center" }}>
                                                <Text style={{ fontSize: 12, fontWeight: "500" }}>
                                                    {applyDiscount &&
                                                        <span dangerouslySetInnerHTML={{ __html: t('checkout_15-policy-with-popup-discount', { normalDuration: selectedPlan.normal_duration, trialDuration: selectedPlan.trial_duration, subscriptionPriceDiscount: wtFormatRoundedValue(selectedPlan.discount, currency, false, 2), subscriptionPriceRegular: wtFormatRoundedValue(selectedPlan.price, currency, false, 2) }) }} />
                                                    }

                                                    {!applyDiscount &&
                                                        <span dangerouslySetInnerHTML={{ __html: t('checkout_15-policy', { normalDuration: selectedPlan.normal_duration, trialDuration: selectedPlan.trial_duration, subscriptionPriceRegular: wtFormatRoundedValue(selectedPlan.price, currency, false, 2) }) }} />
                                                    }
                                                </Text>
                                            </Col>
                                            <Col xs={2} sm={2} md={7} lg={7} xl={7}></Col>
                                        </Row>
                                    }
                                </Col>
                            </Row>

                        </Col>
                    </Col>
                </Row>


            }
        </React.Fragment >
    );
}

export default Level108Quiz;
