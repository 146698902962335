import React, { useEffect, useState } from 'react';
import { Button, Col, Row, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import Loading from '../../../helpers/commons/loader';
import { wtGetFromLocalStorage, wtSaveToLocalStorage } from '../../../helpers/commons/LocalStorage';
import { useNavigate } from 'react-router-dom';

const { Title } = Typography;

const Level106Quiz = ({ quiz }) => {
    const { t } = useTranslation('website');
    const navigate = useNavigate();
    const authTokenWebsite = wtGetFromLocalStorage('authTokenWebsite', '');
    const authUserWebsite = wtGetFromLocalStorage('authUserWebsite', null);

    const [texts, setTexts] = useState([]);
    const [isFading, setIsFading] = useState(false);


    useEffect(() => {
        if (authTokenWebsite === '' || quiz === null) {
            navigate('/');
        }
        if (quiz.palmistry_final_key != '') {
            setTexts([
                t('palm_5-closing_4-pop_animation_part-1'),
                t('palm_5-closing_4-pop_animation_part-2'),
                t(quiz.palmistry_final_key),
                t('palm_5-closing_4-pop_animation_part-4')
            ]);
        }
    }, [authTokenWebsite, quiz]);

    const [isLoading, setIsLoading] = useState(false);
    const [textIndex, setTextIndex] = useState(0);



    useEffect(() => {
        const interval = setInterval(() => {
            setIsFading(true); // Start fade-out effect
            setTimeout(() => {
                setTextIndex((prevIndex) => {
                    if (prevIndex < texts.length - 1) {
                        return prevIndex + 1;
                    } else {
                        clearInterval(interval);
                        handleFinalTextChange();
                        return prevIndex;
                    }
                });
                setIsFading(false); // Start fade-in effect
            }, 1000); // Wait for fade-out to finish
        }, 4000); // Change text every 4 seconds

        return () => clearInterval(interval);
    }, [texts]);

    const handleFinalTextChange = () => {
        setTimeout(() => {
            const newQuiz = { ...quiz, level: +authUserWebsite.language.checkout_type === 0 ? 108 : 107 };
            wtSaveToLocalStorage('quiz', newQuiz);
            navigate('/palmistry-checkout')
        }, 0);
    };

    //if loading true then return spinner
    if (isLoading) {
        return <Loading />;
    }

    return (
        <React.Fragment>
            {(authTokenWebsite !== '' && quiz !== null) &&
                <Row justify="center">
                    <Col xs={24} sm={22} md={16} lg={14} xl={12} className="responsive-div">
                        <Row justify="center" align="middle" style={{ height: '60vh' }}>
                            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                <Title level={4} className={`quiz-level-5-title ${isFading ? 'fade-out' : 'fade-in'}`}>
                                    {texts[textIndex]}
                                </Title>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            }
        </React.Fragment>
    );
}

export default Level106Quiz;
