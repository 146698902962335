import axios from "axios";
import HttpHelper from "../../httpHelper";
import { saveAs } from 'file-saver';
import { AppConstants } from "../constants";
import { wtSaveToLocalStorage, wtGetFromLocalStorage } from "../LocalStorage";
import { currentDateTime } from "../moment";
import { showErrorNotification } from "../notifications";


export const wtApiCall = async (url, data, method, successCallback, failureCallback, isWebsiteCall = false, timeout = 90000, regardless = function () { }) => {

  let authToken = wtGetFromLocalStorage('authToken', '');
  let authTokenWebsite = wtGetFromLocalStorage('authTokenWebsite', '');
  let remember = wtGetFromLocalStorage('remember', false);
  let idleTimeout = wtGetFromLocalStorage('idle_timeout', currentDateTime());
  // Build headers to send
  const headers = {
    'Content-type': 'application/json',
  };

  // Set method if not set already
  if (!method) {
    method = 'get';
  }

  // Init data to send (if not already init) and attach basic values to it
  if (!data) {
    data = {};
  }

  // Special handling for laravel resources
  if ('get' === method || 'post' === method) {
    data._method = method;
  } else {
    // this goes for methods like patch, put, delete, etc.
    data._method = method;
    method = 'post';
  }

  //Send idle timeout and current time for verification of idle system
  data._idleTimeOut = idleTimeout;
  data._currentDateTime = currentDateTime();
  data.is_website_call = isWebsiteCall;

  // Attach auth token if exists
  if (!isWebsiteCall) {
    if (authToken && authToken !== '') {
      headers.Authorization = 'Bearer ' + authToken;
    }
  }
  if (isWebsiteCall) {
    if (authTokenWebsite && authTokenWebsite !== '') {
      headers.Authorization = 'Bearer ' + authTokenWebsite;
    }
  }

  // Attach remember token if exists
  data.remember = remember;

  // Build settings for the call
  let settings = {
    url,
    headers,
    data,
    timeout: timeout
  };

  // Make the http request object
  const httpRequest = new HttpHelper();

  if (method === 'get') {
    settings['params'] = data;
  }
  else {
    settings['data'] = data;
  }

  // Set failure callback if not set already
  if (!failureCallback) {
    failureCallback = (result) => {
      showErrorNotification(result);
    };
  }

  // Call based on method
  if ('get' === method) {
    httpRequest.http_get(
      settings,
      {
        401: () => doLogout(),
        419: () => doLogout(),
        200: (result) => successCallback(JSON.parse(result)),
        500: (result) => failureCallback(JSON.parse(result)),
        403: (result) => failureCallback(JSON.parse(result)),
      },
      regardless
    );
  } else if (method === 'post') {
    httpRequest.http_post(
      settings,
      {
        401: () => doLogout(),
        419: () => doLogout(),
        200: (result) => successCallback(JSON.parse(result)),
        500: (result) => failureCallback(JSON.parse(result)),
        413: (result) => failureCallback(JSON.parse(result)),
        422: (result) => failureCallback(JSON.parse(result)),
        403: (result) => failureCallback(JSON.parse(result)),
      },
      regardless
    );
  } else if (method === 'put') {
    httpRequest.http_put(
      settings,
      {
        401: () => doLogout(),
        200: (result) => successCallback(JSON.parse(result)),
        500: (result) => failureCallback(JSON.parse(result)),
        403: (result) => failureCallback(JSON.parse(result)),
      },
      regardless
    );
  }

  else if (method === 'delete') {
    httpRequest.http_delete(
      settings,
      {
        401: () => doLogout(),
        200: (result) => successCallback(JSON.parse(result)),
        500: (result) => failureCallback(JSON.parse(result)),
        403: (result) => failureCallback(JSON.parse(result)),
      },
      regardless
    );
  }

};



export const wtParseApiCallFailureMessage = (result) => {
  if (typeof result === 'string') {
    return result;
  }

  let msg = '';

  if (result && result.message) {
    msg = result.message;
    if (result.data) {
      const keys = Object.keys(result.data);
      for (let key of keys) {
        msg += "\n    * " + result.data[key];
      }
    }
  }

  if ('' === msg) {
    if (result && result.exception && result.exception.indexOf('PostTooLargeException') > 0) {
      msg = 'The submitted payload exceeds the max allowed limit!';
    }
  }

  return msg;
};

export const doLogout = () => {
  // Dispatch logout to redux store

  wtSaveToLocalStorage('authUser', null);
  wtSaveToLocalStorage('authToken', '');
  wtSaveToLocalStorage('remember', false);
  wtSaveToLocalStorage('idle_timeout', '');
  wtSaveToLocalStorage('configurations', null);

  wtSaveToLocalStorage('authUserWebsite', null);
  wtSaveToLocalStorage('authTokenWebsite', '');
  wtSaveToLocalStorage('quiz', null);
  wtSaveToLocalStorage('quizDashboard', null);
  wtSaveToLocalStorage('country', null);
  wtSaveToLocalStorage('language', null);
  wtSaveToLocalStorage('authUserUpsell', null);
  wtSaveToLocalStorage('authUserSubscription', null);


  // Invalidate the display
  window.location.reload();
};

export const lzIsArray = (input) => {
  return Array.isArray(input);
};


export const saveAdvisorAvatar = async (url, file, advisorId) => {
  // Get current user
  const authToken = wtGetFromLocalStorage('authToken', '');
  const idleTimeout = wtGetFromLocalStorage('idle_timeout', currentDateTime());

  // Set headers
  const headers = {
    'Content-Type': 'multipart/form-data',
  };

  if (authToken) {
    headers.Authorization = `Bearer ${authToken}`;
  }

  // Prepare FormData
  const formData = new FormData();
  formData.append('avatar', file); // Assuming file is a Blob or File object
  formData.append('_idleTimeOut', idleTimeout);
  formData.append('_currentDateTime', currentDateTime());
  formData.append('is_website_call', false);
  formData.append('advisor_id', advisorId);

  try {
    const response = await axios.post(AppConstants.api_base_url + url, formData, {
      headers: headers,
    });

    // Handle response
    if (response.status >= 200 && response.status < 300) {
      // Success
      return response.data; // Axios automatically parses JSON
    } else {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
  } catch (error) {
    // Handle errors
    showErrorNotification('Error saving blob:', error.message || error);
  }
};


export const wtApiPostWithContentTypeHandling = async (url, data) => {

  let idleTimeout = wtGetFromLocalStorage('idle_timeout', currentDateTime());
  let authToken = wtGetFromLocalStorage('authToken', '');

  // Build headers to send
  const headers = {
    'Content-type': 'application/json',
  };


  // Init data to send (if not already init) and attach basic values to it
  if (!data) {
    data = {};
  }

  // Special handling for laravel resources
  data._method = 'POST';

  //Send idle timeout and current time for verification of idle system
  data._idleTimeOut = idleTimeout;
  data._currentDateTime = currentDateTime();

  // Attach auth token if exists
  if (authToken && authToken !== '') {
    headers.Authorization = 'Bearer ' + authToken;
  }

  // Make init data
  const initData = {
    method: 'POST',
    body: JSON.stringify(data),
    headers,
  };

  const response = await fetch(AppConstants.api_base_url + url, initData);

  // If response is a csv then show it
  const responseContentType = response?.headers?.get('Content-Type');
  if (responseContentType?.indexOf('text/csv') >= 0) {
    const filename = response.headers.get('Content-Disposition')?.replace('attachment; filename=', '') || 'export.csv';
    response.blob().then((blobData) => lzDownloadBlobFile(blobData, filename));
    return null;
  }

  if (responseContentType?.indexOf('application/pdf') >= 0) {
    const filename = response.headers.get('Content-Disposition')?.replace('attachment; filename=', '') || 'report.pdf';
    response.blob().then((blobData) => lzDownloadBlobFile(blobData, filename));
    return null;
  }

  // Parse the json out of the response
  try {
    const json = await response.json();

    // Handle the data
    if (!json || typeof json.data === 'undefined' || typeof json.message === 'undefined') {
      showErrorNotification(json.message || 'Invalid data returned from server.');
      return null;
    }

    // Validate success and return it if valid
    if (json.success) {
      return json.data;
    }

    showErrorNotification(json.message);
  } catch (e) {
    showErrorNotification('An error has occured in the api call to server! User does not have rights');
  }
  return null;
};

export const lzDownloadBlobFile = (blob, filename) => {
  saveAs(blob, filename);
};
